
/* eslint-disable no-console */
import Peer from "peerjs"
import { Component, Vue } from "vue-property-decorator"

import { GET_MP_ACTIVE, MP_CHARS } from "@/store/store-types"
import MultiplayerCharList from "@/components/multiplay/MultiplayerCharList.vue"
import ChatWindow from "@/components/multiplay/ChatWindow.vue"
import FLInput from "@/components/base/FLInput.vue"
import FLButton from "@/components/base/FLButton.vue"
import FLCheckbox from "@/components/base/FLCheckbox.vue"
import { UserData } from "@/components/multiplay/protocol"
import { randomName } from "@/util/util"

const REGEX_ROOM_NAME = /[^a-zA-Z -]/g

@Component({
  components: {
    ChatWindow,
    FLButton,
    FLCheckbox,
    FLInput,
    MultiplayerCharList,
  },
})
export default class Multiplayer extends Vue {
  roomName: string = randomName(3)
  userName: string = randomName(2)
  hostGame: boolean = false

  get connected() {
    return this.$store.getters[GET_MP_ACTIVE]
  }
  get startGameText() {
    return this.hostGame ? "Host game" : "Join game"
  }
  get mpChars() {
    return this.$store.state[MP_CHARS]
  }
  get userData(): UserData {
    return {
      characters: this.mpChars,
      peerId: "",
      role: "player",
      origin: "FLC",
      username: this.userName,
    }
  }
  get canJoin(): boolean {
    return (
      this.validRoomName &&
      this.userName &&
      this.mpChars &&
      this.mpChars.length > 0
    )
  }
  get validRoomName(): boolean {
    if (!this.roomName) return false
    return !this.wrongChar
  }
  get wrongChar(): string {
    return [...(this.roomName.match(REGEX_ROOM_NAME) || [])].join("") || ""
  }

  async onClickConnect() {
    const payload = {
      userData: this.userData,
      roomName: this.roomName,
    }
    if (this.hostGame) {
      // Host game
      return await this.$store.dispatch("hostGame", payload)
    } else {
      // Join game
      return await this.$store.dispatch("joinGame", payload)
    }
  }
  async onClickDisconnect() {
    await this.$store.state.client.stop()
    if (this.$store.state.host.connected) {
      await this.$store.state.host.stop()
    }
  }
}
