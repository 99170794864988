
import Vue from "vue"
import {
  CharacterData,
  validateNewCharacter,
} from "@/data/character/characterData"
import Modal from "@/components/Modal.vue"

const VALID = "✓"
const INVALID = "✖"
export const EV_EDIT_CARD = "edit-card-clicked"
export const EV_CARD_CLICKED = "char-card-clicked"

/*
 * A component that displays a picture and character data.
 *
 * TODO: Cleanup, make extensible, use as base for other character card
 * implementations.
 */
export default Vue.extend({
  name: "CharacterCard",
  components: {},
  props: {
    charData: Object as () => CharacterData | null,
    titleOverride: String,
    linkOverride: String,
    clickDisabled: Boolean,
    link: String,
  },
  data() {
    return {
      modalActive: false,
      actionsActive: false,
      modalConfirmActivate: false,
    }
  },
  computed: {
    characterId(): string {
      if (!this.charData) return ""
      return this.charData.metadata.id
    },
    empty(): boolean {
      return !this.charData
    },
    cardLink(): string {
      // TODO: Convert to prop
      if (this.link) return this.link
      if (this.linkOverride) return this.linkOverride
      if (this.empty) return `new`
      if (this.newChar) return `new/edit/${this.characterId}`
      return `active/edit/${this.characterId}`
    },
    newCharValid(): boolean {
      return !!this.charData && validateNewCharacter(this.charData)
    },
    newChar(): boolean {
      return (
        !!this.charData &&
        ["new", undefined].includes(this.charData.metadata.status)
      )
    },
  },
  methods: {
    edit(ev: Event) {
      if (ev) ev.preventDefault()
      this.$emit(EV_EDIT_CARD)
      if (!this.clickDisabled) this.$router.push(this.cardLink)
    },
    cardClicked() {
      if (!this.clickDisabled) this.actionsActive = !this.actionsActive
      this.$emit(EV_CARD_CLICKED, this.cardLink)
    },
    remove() {
      if (this.charData) {
        this.$emit("remove-card", this.charData.metadata.id)
      }
      this.closeModal()
    },
    confirmRemove() {
      this.modalActive = true
    },
    closeModal() {
      this.modalActive = false
    },
    activateClicked() {
      if (this.newChar && !this.newCharValid) {
        this.modalConfirmActivate = true
      } else {
        this.activate()
      }
    },
    activate() {
      this.charData && this.$emit("activate", this.charData.metadata.id)
      this.actionsActive = false
    },
    deactivate() {
      this.charData && this.$emit("free-edit", this.charData.metadata.id)
      this.actionsActive = false
    },
  },
})
