
import { Component, Vue } from "vue-property-decorator"
import {
  GET_MP_ACTIVE,
  GET_MP_PLAYERS,
  GET_MP_PLAYER_SELF,
  SET_MP_CHAR,
  MP_CHARS,
} from "@/store/store-types"
import CharacterCard, {
  EV_CARD_CLICKED,
} from "@/components/base/CharacterCard.vue"
import { CharacterData } from "@/data/character/characterData"
import { UserData } from "@/components/multiplay/protocol"

export const EMIT_SELECTED_CHARS = "mpcharlist selected chars"

/** Display active characters if not online, otherwise list of connected players */
@Component({
  components: {
    CharacterCard,
  },
})
export default class MultiplayerCharList extends Vue {
  selectedChars: (CharacterData | null)[] = this.$store.state[MP_CHARS]

  mounted() {
    const firstActiveChar = this.$characterStore.activeCharacters[0] || null
    this.portraitClicked(firstActiveChar) // select first active char
  }

  destroyed() {
    !this.mpActive && this.$store.commit(SET_MP_CHAR, [null]) // reset selected chars
  }

  portraitClicked(charData: CharacterData | null) {
    if (charData) {
      const alreadySelected = this.isSelected(charData)
      if (alreadySelected) {
        this.selectedChars = this.selectedChars.filter(
          (char) => char && char.metadata.id !== charData.metadata.id
        )
        // this.selectedChars = []
      } else {
        // this.selectedChars.push(charData) // for selecting multiple chars
        this.selectedChars = [charData]
      }
    } else {
      this.selectedChars = [null]
    }
    this.$emit(EMIT_SELECTED_CHARS, this.selectedChars)
    this.$store.commit(SET_MP_CHAR, this.selectedChars)
  }
  isSelected(charData: CharacterData | null): boolean {
    if (!charData) {
      return this.selectedChars.find((char) => char === null) === null
    }
    return !!this.selectedChars.find(
      (char) => !!char && char.metadata.id === charData.metadata.id
    )
  }
  linkOverride(charData: CharacterData): string {
    return ""
  }

  get mpActive(): boolean {
    return this.$store.getters[GET_MP_ACTIVE]
  }
  get userList() {
    return this.$store.getters[GET_MP_PLAYERS]
  }
  get activeCharacters() {
    return [...this.$characterStore.activeCharacters, null]
  }
  get selfPlayer(): UserData | undefined {
    return this.$store.getters[GET_MP_PLAYER_SELF]
  }
  get id(): string {
    return this.$store.state.client.peerId
  }

  thisUserClass(user: UserData): string {
    return this.id === user.peerId ? "bold" : ""
  }
  viewCharLink(user: UserData, charData: CharacterData): string {
    if (user.peerId === this.id) {
      return `/active/view/${charData.metadata.id}`
    }
    return `/multiplayer/view/${user.peerId}/${charData.metadata.id}`
  }
  filtered(characters: CharacterData[]) {
    return characters.filter((char) => !!char)
  }
  mpCardClicked(cardLink: string) {
    this.$router.push(cardLink)
  }
}
