
/* eslint-disable no-console */
import Peer from "peerjs"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"

import {
  GET_MP_ACTIVE,
  GET_ROOM_NAME,
  SET_OTHER_CHARS,
} from "@/store/store-types"
import { ChatMessage } from "@/components/multiplay/fl-node"
import { FLPlayer } from "@/components/multiplay/fl-player"
import { Protocol, ProtocolTypes, UserData } from "./protocol"

import FLInput from "@/components/base/FLInput.vue"
import SvgIcon from "@/components/SvgIcon.vue"
import IconButton from "@/components/base/IconButton.vue"

/*
 * Vue component for displaying chat messages
 */
@Component({
  components: {
    FLInput,
    IconButton,
    SvgIcon,
  },
})
export default class ChatWindow extends Vue {
  @Prop({ required: true }) userName!: string
  @Prop({ required: false }) admins!: string[]

  get client(): FLPlayer {
    return this.$store.state.client
  }

  chatMessage = ""

  get users(): UserData[] {
    return this.client.users
  }

  get id() {
    return this.client.peerId
  }

  get charData() {
    return this.$store.state.multiPlayerCharacter
  }

  /** Return chat messages. Has side effect of scrolling down chat container. */
  get messages(): ChatMessage[] {
    const chatBox = this.$refs.messageBox as any
    chatBox &&
      this.$nextTick(() => {
        chatBox.scrollTop = chatBox.scrollHeight
      })
    return this.client.messages
  }

  get roomName() {
    return this.$store.getters[GET_ROOM_NAME]
  }
  get mpActive() {
    return this.$store.getters[GET_MP_ACTIVE]
  }

  nameIsBold(user: UserData): string {
    return this.id === user.peerId ? "bold" : ""
  }

  formatUserName(user: UserData) {
    if (["gm", "admin"].includes(user.role)) {
      return `GM ${user.username}`
    }
    return user.username
  }

  formatChat(chat: ChatMessage): string {
    if (chat.message.type === "!? server chat") {
      return `<< ${chat.message.msg} >>`
    }
    return `${chat.username}: ${chat.message.msg}`
  }

  _sendMsg(msg: string) {
    if (!msg) return
    const chatMsg: Protocol.Chat = {
      type: ProtocolTypes.chat,
      msg,
      from: this.id,
      to: null,
    }
    this.client.broadcast(chatMsg)
    this.chatMessage = ""
  }
}
